<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show[0]">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            entity_info
              ? $t("customLogin.Edit sync config")
              : $t("customLogin.Create adapter config")
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        自定义登录信息列表项
      </v-alert>
      <v-row align="center" v-if="external_id">
        <v-col cols="3">
          <v-subheader> 登录地址</v-subheader>
        </v-col>
        <v-col cols="9">
          <span style="font-size:14px;color:rgba(0,0,0,0.6)"
            >{{ localUrl }}/logins/{{ external_id }}.html</span
          >
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader
            ><span class="red--text">*</span>
            {{ $t("customLogin.label.external_id") }}</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model.trim="external_id"
            persistent-hint
            required
            :rules="rules.isEnglish"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader
            ><span class="red--text">*</span>
            {{ $t("customLogin.label.name") }}</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model.trim="name"
            persistent-hint
            required
            :rules="rules.notNullRules"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex" cols="3"></v-col>
        <v-col cols="9" class="text-left">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="entity_info ? modify() : create()"
            >{{ entity_info ? "提交" : "创建" }}</v-btn
          >
          <v-btn
            class="ml-6"
            elevation="0"
            medium
            color="gary"
            @click="resetForm()"
            >重置</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "CreateAccount",
  props: ["entity_info", "is_modify", "show"],
  data() {
    let isChinese = v => {
      let reg = /[\u4E00-\u9FA5]/g;
      return v && !reg.test(v);
    };
    return {
      external_id: "",
      name: "",
      valid: true,
      localUrl: "",
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isEnglish: [v => isChinese(v) || "只能包含数字和字母"]
      }
    };
  },
  created() {
    this.localUrl = window.location.origin;
    if (this.entity_info) {
      this.fetchData();
    }
  },

  methods: {
    modify() {
      if (this.$refs.form.validate()) {
        let payload = {
          external_id: this.external_id,
          name: this.name
        };
        this.$http
          .post(`api/custom_login/${this.$route.params.id}/modify`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            bus.$emit("refresh");

            return "自定义登录已成功修改";
          })
          .catch(({ code, message }) => {
            throw `自定义登录修改失败：${this.$t(
              `${"api." + code}`
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        let payload = {
          external_id: this.external_id,
          name: this.name
        };

        this.$http
          .post(`api/custom_login/create`, payload)
          .delegateTo(api_request)
          .then(data => {
            this.$set(this.show, 0, false);
            bus.$emit("refreshList");
            this.$router.push(`/customLogin/view/${data.id}`);
            return "创建成功";
          })
          .catch(({ code, message }) => {
            throw `自定义登录创建失败：${this.$t(
              `${"api." + code}`
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    fetchData() {
      if (this.entity_info) {
        let { external_id, name } = this.entity_info;
        this.external_id = external_id;
        this.name = name;
      }
    },
    reset() {
      this.$refs.form.reset();
    }
  },
  watch: {
    watchShow(val) {
      if (this.is_modify && val) {
        return this.fetchData();
      } else {
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    watchShow() {
      return this.show[0];
    }
  }
};
</script>

<style lang="sass">
.v-card__actions
  justify-content: flex-end
  .v-btn
    font-weight: bold
</style>
