<template>
  <div class="CustomList">
    <Breadcrumbs class="main-top">
      <template slot="action-buttons">
        <v-btn depressed color="primary" @click="doCreate">
          {{ $t("customLogin.Create CustomLogin") }}
        </v-btn>
      </template>
    </Breadcrumbs>
    <v-alert
      outlined
      dense
      text
      type="info"
      icon="mdi-lightbulb-on-outline"
      class="text-left rounded-sm body-2 pa-4 content-wrapper"
    >
      <span>自定义登录</span><br />
      用户可以根据自身系统个性需要自定义设置登录页整体风格，自定义登录设置分别包括PC端及移动端设备登录页风格。
    </v-alert>

    <DefaulLoginTable></DefaulLoginTable>

    <v-simple-table class="table-wrapper">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="pl-4 text-left" style="width:200px">
              {{ $t("CustomLogin ID") }}
            </th>
            <th class="text-left" style="width:400px">
              {{ $t("CustomLogin Name") }}
            </th>
            <th class="text-left">
              {{ $t("CustomLogin Url") }}
            </th>
            <th class="text-left" style="width:150px">
              启用
            </th>
            <th class="text-center" style="width:100px">
              {{ $t("Actions") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="custom_logins.length > 0">
          <tr v-for="item in custom_logins" :key="item.id" class="text-left">
            <td class="pl-4">{{ item.external_id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.url }}</td>
            <td>
              <v-switch
                inset
                hide-details="true"
                dense
                v-model="item.enabled"
                :loading="item.loading"
                :disabled="item.builtin"
                @click.stop="change(item)"
              ></v-switch>
            </td>
            <td class="text-center">
              <v-btn icon :to="`/customLogin/view/${item.id}`">
                <v-icon>
                  mdi-tune-vertical
                </v-icon>
              </v-btn>
              <v-btn
                icon
                elevation="0"
                color="red"
                @click.stop="deleteSource(item)"
              >
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="text-center">
            <td
              colspan="5"
              style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
            >
              暂无数据
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
      v-if="Math.ceil(totalPage / limit) > 1"
      v-model="curPage"
      :length="Math.ceil(totalPage / limit)"
      total-visible="7"
      @input="onPageChange(curPage, limit)"
    ></v-pagination>
    <Component :is="sheet_editor" :show="drawer_status" />
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmBox from "@/components/ConfirmBox";
import CustomEditor from "./editor/CustomEditor";
import DefaulLoginTable from "./editor/DefaulLoginTable";
import bus from "@/util/eventBus";

const EDITORS = {
  CustomEditor: CustomEditor,
  DefaulLoginTable: DefaulLoginTable
};

export default {
  name: "CustomLoginList",
  data() {
    return {
      curPage: 1,
      limit: 10,
      totalPage: 0,
      sheet_editor: null,
      drawer_status: [false, false],
      current_id: null,
      show_confirm: null,
      custom_logins: [],
      sources: [],
      psdSources: [],
      allDatas: {},
      type: null,
      source_id: "",
      curId: null
    };
  },
  created() {
    this.refreshList();
    bus.$off("refreshList").$on("refreshList", () => {
      this.refreshList();
    });
  },
  methods: {
    doCreate() {
      this.sheet_editor = EDITORS["CustomEditor"];
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    onPageChange(curPage, limit) {
      this.refreshList(curPage, limit);
    },
    deleteSource({ id, name }) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除认证方式？ #${id} ${name}`,
          callback: () => {
            return this.$http
              .post(`api/custom_login/${id}/archive`)
              .delegateTo(api_request)
              .then(() => {
                this.refreshList();
                return "认证方式已成功删除";
              })
              .catch(({ code, message }) => {
                throw `认证方式删除失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    },
    change(item) {
      const index = this.custom_logins.findIndex(_item => {
        return item.id === _item.id;
      });

      item.loading = true;
      const operationText = item.enabled ? "开启" : "关闭";
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要${operationText}自定义登录 ${item.name}？`,
          callback: () => {
            return this.$http
              .post(`api/custom_login/${item.id}/toggle`, {
                enabled: item.enabled
              })
              .delegateTo(api_request)
              .then(() => {
                return `自定义登录 ${item.name} 已成功${operationText}`;
              })
              .catch(({ code, message }) => {
                throw `自定义登录 ${item.name} ${operationText}失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          item.enabled = !item.enabled;
        })
        .finally(() => {
          this.refreshList();
          item.loading = false;
          this.$set(this.custom_logins, index, this.custom_logins[index]);
        });
    },
    refreshList(curPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/custom_login/list?offset=${(curPage - 1) * limit}&limit=${limit}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.totalPage = res.total;
          if (res.custom_logins.length > 0) {
            this.custom_logins = res.custom_logins.map(item => {
              item.url = `${window.location.origin}/logins/${item.external_id}.html`;
              return item;
            });
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    }
  },
  components: {
    Breadcrumbs,
    ConfirmBox,
    CustomEditor,
    DefaulLoginTable
  }
};
</script>

<style lang="less" scoped>
.CustomList {
  .table-wrapper {
    min-height: 570px;
  }
  /deep/.v-pagination {
    justify-content: flex-end;
    width: 96%;
  }
  .v-alert {
    /deep/ .v-alert__content {
      color: #213142;
    }
    /deep/ .v-alert__icon {
      font-size: 2rem !important;
      height: 3rem;
    }
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
