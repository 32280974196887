<template>
  <div>
    <v-simple-table style="margin:12px 12px 0 24px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="pl-4 text-left" style="width:200px">
              内置登录界面
            </th>
            <th class="text-left" style="width:400px">
              说明
            </th>
            <th class="text-left">
              登录地址
            </th>
            <th class="text-left" style="width:150px">
              启用
            </th>
            <th class="text-center" style="width:100px">
              {{ $t("Actions") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-left">
            <td class="pl-4">默认登录界面</td>
            <td></td>
            <td>{{ defaultLoginUrl }}</td>
            <td>
              <v-switch
                inset
                hide-details="true"
                dense
                v-model="defaultEnabled"
                :loading="defaultLoading"
                :disabled="defaultBuiltin"
                @click.stop="change()"
              ></v-switch>
            </td>
            <td class="text-center">
              <v-btn icon to="/setting/personalization">
                <v-icon>
                  mdi-tune-vertical
                </v-icon>
              </v-btn>
            </td>
          </tr>
          <tr class="text-left" v-if="0">
            <td class="pl-4">本地用户绑定页面</td>
            <td>xxx说明</td>
            <td></td>
            <td>
              <v-switch
                inset
                hide-details="true"
                dense
                v-model="defaultEnabled"
                :loading="defaultLoading"
                :disabled="defaultBuiltin"
                @click.stop="change()"
              ></v-switch>
            </td>
            <td class="text-center">
              <v-btn icon to="/setting/personalization">
                <v-icon>
                  mdi-tune-vertical
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "DefaultLoginTable",
  data() {
    return {
      defaultLoginUrl: "",
      defaultEnabled: true,
      defaultLoading: false,
      defaultBuiltin: false
    };
  },
  created() {
    this.defaultLoginUrl = `${window.location.origin}/login`;
  }
};
</script>

<style lang="less" scoped>
/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
